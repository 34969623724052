
@import '../theme-bootstrap';

.product-full {
  @include font-smoothing;
  background-color: $color-black;
  color: $color-silver;
  &--inverted-white {
    @include font-smoothing(none);
    background-color: $color-white;
    color: $color-black;
  }
  &__installment_price {
    min-height: 50px;
    .afterpay-installment {
      &__product-price {
        letter-spacing: 0;
        display: block;
        margin-block-start: 16px;
        margin-block-end: 16px;
      }
    }
  }
  .product-makeup-vto--active & {
    height: 100vh;
    @include breakpoint($medium-up) {
      height: initial;
    }
  }
}

.product-full__container {
  max-width: $max-width;
  margin: 0 auto;
  padding: 0 0 30px 0;
  @include breakpoint($medium-up) {
    @include swap_direction(padding, 18px 40px 60px 40px);
  }
}

.product-full__breadcrumb {
  .product-breadcrumb {
    margin: 0;
    // spp has 1280 container but with padding. need to adjust
    .spp__container & {
      margin-#{$ldirection}: -20px;
      @include breakpoint($large-up) {
        margin-#{$ldirection}: 0;
      }
    }
  }
}

.product-full__wrapper {
  position: relative;
  .product-makeup-vto--active & {
    .product-flag__container,
    .product-full__detail,
    .product-full__image {
      display: none;
      @include breakpoint($medium-up) {
        display: initial;
      }
    }
    @include breakpoint($medium-up) {
      display: flex;
    }
  }
  @include breakpoint($medium-up) {
    @include pie-clearfix;
    padding-top: 44px;
  }
}

.product-full__utility-bar {
  position: absolute;
  #{$ldirection}: 15px;
  top: 15px;
  z-index: 2;
  opacity: 1;
  @include breakpoint($medium-up) {
    display: block;
    top: 44px;
    #{$ldirection}: -20px;
  }
  @include breakpoint($large-up) {
    #{$ldirection}: 0;
  }
  > a {
    display: block;
    margin-bottom: 10px;
  }
  > a.product-full__utility-bar__fullscreen-trigger {
    display: none;
    @include breakpoint($medium-up) {
      display: block;
    }
  }
  .product-makeup-vto--active & {
    @include breakpoint($medium-up) {
      pointer-events: none;
      opacity: 0.5;
    }
  }
}

// @TODO: should go inside its own _product_image.scss since its shared
.product-full__image {
  text-align: center;
  position: relative;
  img,
  video {
    height: auto;
  }
  @include breakpoint($medium-up) {
    width: 51.66667%;
    float: #{$ldirection};
    margin: 0 7.91667%;
  }
  &__fullscreen-close {
    display: none;
    position: absolute;
    top: 40px;
    #{$rdirection}: 40px;
    z-index: 1;
    border: 0px;
    i {
      font-weight: bold;
      color: #999999;
      font-size: 18px;
    }
  }
  &.fullscreen-enabled {
    float: none;
    margin: 0px;
    padding: 0px;
    background: $color-black;
    position: fixed;
    top: 0px;
    #{$ldirection}: 0;
    width: 100vw;
    height: 100vh;
    z-index: 99999;
    overflow-y: scroll;
    overflow-x: hidden;
    .product-full__image {
      &__fullscreen-close {
        display: block;
      }
      &-carousel {
        width: 100vw;
        max-width: none;
        img {
          max-height: 100vh;
        }
      }
      &-thumbnails {
        position: absolute;
        top: 90px;
        #{$rdirection}: 40px;
        padding: 0px;
        .product-full__carousel__thumbnail {
          display: block;
          margin: 0px 0px 5px 0px;
          width: 50px;
        }
      }
    }
  }
  .product-makeup-vto--active & {
    display: none;
    visibility: hidden;
    position: absolute;
  }
}

.product-flag__flag {
  position: absolute;
  top: 68px;
  #{$ldirection}: 260px;
  font-size: 14px;
  line-height: 9px;
  width: 64px;
  text-align: center;
  @include breakpoint($medium-up) {
    top: 130px;
    #{$ldirection}: 520px;
  }
}
// prevent body scrolling
body.fullscreen-enabled {
  overflow: hidden;
  position: fixed;
}

.product-full__image-carousel {
  max-width: 490px;
  margin: 0 auto;
  .carousel-controls {
    text-align: #{$ldirection};
    top: 45%;
    @include breakpoint($medium-up) {
      top: 40%;
    }
  }
  .carousel-controls .slick-prev {
    #{$ldirection}: 0;
    @include breakpoint($medium-up) {
      #{$ldirection}: -10px;
    }
  }
  .carousel-controls .slick-next {
    #{$rdirection}: 0;
    @include breakpoint($medium-up) {
      #{$rdirection}: -10px;
    }
  }
  .carousel-dots {
    @include breakpoint($medium-up) {
      display: none;
    }
  }
  .slick-arrow {
    color: $color-light-gray-arrow;
    &:hover {
      color: $color-dark-gray;
      background: transparent;
    }
  }
  .slick-dots li button:before {
    background: none;
    border: 1px solid $color-white;
    .product-full--inverted-white &,
    .product-quickshop--inverted-white & {
      background: $color-medium-stone-gray;
      border: 0;
    }
  }
  .slick-dots li.slick-active button:before {
    background: $color-white;
    .product-full--inverted-white &,
    .product-quickshop--inverted-white & {
      background: $color-black;
    }
  }
}

// style skeleton components to prevent FOUC before carousel inits
.product-full__carousel__slide,
.slick-slide {
  display: block;
  + .product-full__carousel__slide {
    display: none;
  }
}

.product-full__image-thumbnails {
  display: none;
  @include breakpoint($medium-up) {
    @include clearfix;
    display: block;
    padding: 20px 0;
  }
}

.product-full__carousel__thumbnail {
  display: inline-block;
  width: 60px;
  cursor: pointer;
  margin: 0 5px 10px 5px;
  img {
    border: none;
    border-bottom: 2px solid transparent;
  }
  &:hover {
    img {
      border: none;
      border-bottom: 2px solid $color-thumbnail-border-grey;
      .product-full--inverted-white &,
      .product-quickshop--inverted-white & {
        border-color: $color-silver;
      }
    }
  }
  &.active {
    cursor: default;
    img {
      border: none;
      border-bottom: 2px solid $color-thumbnail-border-grey;
      .product-full--inverted-white &,
      .product-quickshop--inverted-white & {
        border-color: $color-stone-gray;
      }
    }
  }
}

.product-full__detail {
  position: relative;
  padding: 15px;
  z-index: 1;
  @include breakpoint($medium-up) {
    width: 32.5%;
    float: #{$rdirection};
    padding: 0;
  }
}

.product-full__header {
  @include swap_direction(margin, 0 0 16px 0);
  @include breakpoint($medium-up) {
    margin-bottom: 16px;
  }
}

.product-full__name {
  @include font--edwardian-script;
  @include font-smoothing;
  font-size: 35px;
  line-height: 30px;
  margin: 0;
  @include breakpoint($medium-up) {
    font-size: 50px;
    line-height: 45px;
  }
}

.product-full__subline {
  @include swap_direction(margin, 5px 0 0 0);
}

.product-full__size {
  @include swap_direction(margin, 0 0 10px 0);
  @include breakpoint($medium-up) {
    margin-bottom: 25px;
  }
  .product-sku-size {
    text-transform: lowercase;
    &:first-letter {
      text-transform: uppercase;
    }
  }
}

.product-full__short-desc {
  @include swap_direction(margin, 0 0 10px 0);
  @include breakpoint($medium-up) {
    margin-bottom: 15px;
  }
}

.product-rating {
  margin-block-end: 15px;
  min-height: 30px;
  width: 100%;
}

.product-full__price {
  @include swap_direction(margin, 0 0 25px 0);
  font-weight: 500;
  font-size: 14px;
  @include breakpoint($medium-up) {
    font-size: 18px;
  }
  .product-sku-price {
    min-height: 24px;
    @include breakpoint($medium-up) {
      min-height: 30px;
    }
  }
  .product-sku-unit-price__value {
    font-size: 11px;
    font-weight: 400;
    @include breakpoint($medium-up) {
      font-size: 12px;
    }
  }
}

.product-full__dropdown-wrapper {
  @include clearfix;
  margin: 0 0 15px 0;
  @include breakpoint($medium-up) {
    @include swap_direction(padding, 5px 0 0 0);
    margin-bottom: 20px;
  }
  select,
  a.selectBox {
    @include swap_direction(padding, 0 30px 0 0);
    min-width: 100%;
    max-width: 100%;
    @include breakpoint($medium-up) {
      height: 50px;
      line-height: 50px;
    }
  }
  .product-full__qty {
    select {
      &.selectBox {
        @include breakpoint($tablet-only) {
          padding-#{$ldirection}: 3px;
        }
        @include breakpoint($landscape-up) {
          padding-#{$ldirection}: 15px;
        }
      }
    }
  }
  select {
    // mobile (non-selectbox) select
    padding-#{$ldirection}: 15px;
  }
}

.product-full__sku {
  float: #{$ldirection};
  width: 75%;
  .sku-menu__onesize {
    padding-top: 10px;
  }
}

.product-full__qty {
  float: #{$rdirection};
  width: 21%;
  a.selectBox--inverted {
    .selectBox-label {
      color: $color-white;
    }
  }
}

.product-full__buttons {
  @include swap_direction(margin, 0 0 20px 0);
  .button {
    @include button;
    @include button-inverted;
    width: 100%;
    &.button--disabled {
      @if $cart-button-disable {
        background: $color-light-gray;
        color: $color-white;
        border-color: $color-light-gray;
      } @else {
        background: $color-button-light-gray;
        color: $color-black;
      }
      cursor: default;
    }
  }
}

.product__inventory-status {
  @include swap_direction(margin, 0 0 20px 0);
  text-align: center;
}

.product-full__offer {
  @include swap_direction(margin, 0 0 20px 0);
  @include breakpoint($medium-up) {
    @include swap_direction(margin, 0 0 35px 0);
  }
  text-align: center;
}

.product__inventory-status-item,
.product-offer__text {
  @include font-smoothing(none);
  @include tracking(100);
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  @include breakpoint($medium-up) {
    font-size: 12px;
  }
}

.product-full__accordion {
  padding-top: 20px;
  @include breakpoint($medium-up) {
    padding-top: 25px;
    margin-bottom: 20px;
  }
}

.product-full__media-container {
  display: flex;
  position: relative;
  width: 100%;
  flex-direction: column;
  @include breakpoint($medium-up) {
    position: absolute;
  }
}

.product-full__makeup-vto {
  display: contents;
}

ul.selectBox-dropdown-menu {
  &.sku-menu-selectBox-dropdown-menu,
  &.product-qty-select__selectbox-selectBox-dropdown-menu {
    &.selectBox-attached-selectBox-dropdown-menu {
      z-index: 999;
    }
  }
}
